export const promotionCodes =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
    ? [
        {
          name: "CampaignWorld",
          percentage: 40,
          code: "promo_1OvQ9tC5cLI7zqt3bLwiA2Bp", // LOCALHOST
        },
        {
          name: "TalkNow",
          percentage: 30,
          code: "promo_1QGj67C5cLI7zqt39jb15XKq",
        },
        {
          name: "SpeakNow",
          percentage: 20,
          code: "promo_1QGj6jC5cLI7zqt3PY8Wpyus",
        },
        {
          name: "TimeToTalk",
          percentage: 15,
          code: "promo_1QGj7BC5cLI7zqt3956rW1OF",
        },
      ]
    : [
        {
          name: "CampaignWorld",
          percentage: 40,
          code: "promo_1PHs9wC5cLI7zqt3MC0214Ja",
        },
        {
          name: "TalkNow",
          percentage: 30,
          code: "promo_1QCgyuC5cLI7zqt3gLqLWRlX",
        },
        {
          name: "SpeakNow",
          percentage: 20,
          code: "promo_1PyY01C5cLI7zqt3Y6QZMD8Q",
        },
        {
          name: "TimeToTalk",
          percentage: 15,
          code: "promo_1QGikFC5cLI7zqt3i902685v",
        },
      ];
