import { type IPinfo } from "node-ipinfo";
import { useEffect, useState } from "react";

export function useLocationDetector(props?: { disable?: boolean }) {
  const [locationData, setLocationData] = useState<IPinfo>();

  useEffect(() => {
    async function fetchLocation() {
      const response = await fetch("/api/detect-location");
      const data = await response.json();
      setLocationData(data);
    }

    if (props?.disable) {
      // no need to pay for location detection if not needed
      return;
    }

    fetchLocation();
  }, [props?.disable]);

  return locationData;
}
