import base64url from "base64url";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { siteConfig } from "../configuration/config";
import { ProductResponse } from "../pages/api/plans";
import { fetchApi } from "../services/fetch-api";
import { AppError } from "../utils/app-error";
import { logger } from "../utils/logger";

interface Props {
  withCheckoutLink?: boolean;
  promotionCodeId?: string;
  currencyCode?: string;
}

export function usePlansLanding(props: Props) {
  const { withCheckoutLink = false, promotionCodeId, currencyCode } = props;
  const [plansDidLoad, setPlansDidLoad] = useState(false);
  const { data, status } = useSession();
  const sessionUser = data?.user;

  let checkoutLinkParams = "";
  let includeArchivedParams = "";
  let currencyCodeParams = "";
  let invalidCheckoutLink = false;

  if (sessionUser) {
    const userId = (sessionUser as any)?.id;
    const base64sessionUser = base64url(userId);
    const hasStripeRequirements = Boolean(base64sessionUser && sessionUser?.email);

    if (withCheckoutLink && hasStripeRequirements) {
      checkoutLinkParams = `customer-id=${base64sessionUser}&email=${sessionUser.email}`;

      if (promotionCodeId) {
        checkoutLinkParams += `&promotion-code-id=${promotionCodeId}`;
      }
    } else {
      withCheckoutLink && logger.warn("usePlans: withCheckoutLink requires a sessionUser");
      invalidCheckoutLink = true;
    }
  }

  if (currencyCode) {
    currencyCodeParams = `&currency-code=${currencyCode}`;
  }

  const response = useSWR<ProductResponse, AppError>(
    siteConfig.isDefault
      ? `/plans?${checkoutLinkParams}${includeArchivedParams}${currencyCodeParams}`
      : null,
    fetchApi,
  );

  useEffect(() => {
    if (!plansDidLoad && !response.isLoading && response.data) {
      setPlansDidLoad(true);
    }
  }, [response.data, plansDidLoad, response.isLoading]);

  return {
    plans: response.data,
    productsError: response.error,
    plansAreLoading: response.isLoading,
    plansDidLoad,
    invalidCheckoutLink: !response.isLoading && invalidCheckoutLink,
  };
}
