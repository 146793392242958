import { Box } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
  maxWidthTemplate?: "wide" | "full" | "content";
  height?: string;
}

export default function PageContainer(props: Props) {
  let maxW = "1200px";

  if (props.maxWidthTemplate === "content") {
    maxW = "900px";
  } else if (props.maxWidthTemplate === "full") {
    maxW = "100%";
  }

  return (
    <Box
      id="page-container"
      py={5}
      px={{ base: 5, md: 10 }}
      overflowY="auto"
      maxH={"100%"}
      height={props.height}
    >
      <Box maxW={maxW} margin="0 auto">
        {props.children}
      </Box>
    </Box>
  );
}
