import { Box } from "@chakra-ui/react";
import Script from "next/script";

export default function SenjaTestimonialsSmall() {
  return (
    <Box id="senja-testimonials" overflowX="hidden" w="270px">
      <Box
        w="300px"
        className="senja-embed"
        data-id="b7414eb1-89a0-4929-8657-5b05dbabcaad"
        data-mode="shadow"
        data-lazyload="false"
      ></Box>
      <Script
        src="https://widget.senja.io/widget/b7414eb1-89a0-4929-8657-5b05dbabcaad/platform.js"
        strategy="lazyOnload"
      />
    </Box>
  );
}
