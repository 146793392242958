import { useState, useEffect } from "react";

export function useSessionStorage<T>(storageKey: string, initialValue?: T) {
  const [storageData, setStorageData] = useState<T>();
  const [isLoadingStorage, setIsLoadingStorage] = useState(true);

  useEffect(() => {
    if (initialValue !== undefined) {
      setStorageData(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    // Retrieve data from sessionStorage during the client-side hydration process
    const storedData = sessionStorage.getItem(storageKey);
    if (storedData) {
      setStorageData(JSON.parse(storedData));
    }

    setIsLoadingStorage(false);
  }, [storageKey]);

  useEffect(() => {
    if (storageData !== undefined) {
      // Store data to sessionStorage whenever 'data' changes
      sessionStorage.setItem(storageKey, JSON.stringify(storageData));
    }
  }, [storageData, storageKey]);

  const clearStorage = () => {
    sessionStorage.removeItem(storageKey);
    setStorageData(undefined);
  };

  return {
    storageData,
    setStorageData,
    isLoadingStorage,
    clearStorage,
  };
}
